.indent {
  padding-left: 1em;
}

.storage {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  font-family: monospace;
  column-gap: 0.5em;

  text-align: right;

  --green: green;
  --red: red;
}

.priority {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  font-family: monospace;
  column-gap: 0.5em;
}

.eta {
  white-space: nowrap;
}

.reset-button {
  position: fixed;
  top: 0;
  right: 0;
}
